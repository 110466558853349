
























import {Component, Vue, Watch} from 'vue-property-decorator';
import TopModels from '@/components/TopModels.vue';
import {vxm} from '@/store';
import Loader from '@/components/Loader.vue';
import PostCardNew from '@/components/PostCardNew.vue';
import {VideoInterface} from '@/types/videoInterface';

@Component({
  components: {Loader, TopModels, PostCardNew},
})
export default class Favorites extends Vue {
  isMobile = false;
  viewType = 'grid';
  vids: VideoInterface[] = [];
  isRequestSend = false;
  totalVids = 0;
  params = {
    page: 1,
    take: 10,
    type: 'favorites',
  };
  randomPromoIndex = this.randomInt(3, 8);

  created() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  mounted() {
    this.getVids();
    // window.addEventListener('scroll', this.handleScroll);
  }

  destroyed() {
    window.removeEventListener('resize', this.onResize);
    // window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    // const bottomOfWindow = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;
    if (!this.isRequestSend && this.totalVids > this.vids.length) {
      this.getVids();
    }
  }

  // get random index to create blank space in grid
  randomInt(min: number, max: number) {
    return min + Math.floor((max - min) * Math.random());
  }

  get paymentSuccessCount() {
    return this.$store.state.paymentSuccessCount;
  }

  @Watch('paymentSuccessCount') onPaymentSuccess() {
    const take = (this.params.page as number) * (this.params.take as number);
    this.isRequestSend = true;
    vxm.fan
      .getFavorites({...this.params, page: 1, take: take})
      .then((res) => {
        this.totalVids = res.data.count;
        this.vids = res.data.items;
      })
      .catch((error) => {
        return error;
      })
      .then(() => {
        this.isRequestSend = false;
      });
  }

  getVids() {
    this.isRequestSend = true;

    vxm.fan
      .getFavorites(this.params)
      .then((res) => {
        this.totalVids = res.data.count;
        this.vids.push(...res.data.items);
        (this.params.page as number)++;
      })
      .catch((error) => {
        return error;
      })
      .then(() => {
        this.isRequestSend = false;
      });
  }

  switchView(viewType: string) {
    this.viewType = viewType;
  }

  onResize() {
    this.isMobile = window.innerWidth <= 1023;
  }
}
